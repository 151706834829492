import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MEAL_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";
import { message } from "antd";

//CREATE ACTION
export const deleteMealRequest = createAsyncThunk("deleteMealRequest", async ({scheduleId, uuid}, { rejectWithValue }) => {
    try {
        const response = await axiosHttp.delete(`${MEAL_BASE_URL}/meal-request/admin?scheduleId=${scheduleId}&uuid=${uuid}`);
        message.success(response.data.message);
        return response.data;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        message.error(err.response.data.message);
        return rejectWithValue(err.response.data);
    }
});

const MealRequestDeleteSlice = createSlice({
    name: "MealRequestDeleteSlice",
    initialState: {
        isLoading: false,
        isError: false,
        error: null,
        data: null,
    },

    extraReducers: (builder) => {
        builder.addCase(deleteMealRequest.pending, (state, action) => {
            state.isLoading = true;
            state.isError = false;
        });

        builder.addCase(deleteMealRequest.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.data = action.payload;
        });

        builder.addCase(deleteMealRequest.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        });
    },
});

export default MealRequestDeleteSlice.reducer;
