import axiosHttp from "services/axiosHttp";
import { message } from "antd";
import { MEAL_BASE_URL } from "configs/AppConfig";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");


//action
export const mealRequestCreate = createAsyncThunk("mealRequestCreate", async ({scheduleId ,data} ,{rejectWithValue}) => {
	try {
		const response = await axiosHttp.post(`${MEAL_BASE_URL}/meal-request/admin?scheduleId=${scheduleId}`, data);
		message.success("Request has been created successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		message.error(err.response.data.message);
		return rejectWithValue(err.response.data);
	}
});

const MealRequestCreateSlice = createSlice({
	name: "MealRequestCreateSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	
	reducers: {
		resetMealRequestCreateState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},
	
	extraReducers: (builder) => {
		builder.addCase(mealRequestCreate.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(mealRequestCreate.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(mealRequestCreate.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export const { resetMealRequestCreateState } = MealRequestCreateSlice.actions;
export default MealRequestCreateSlice.reducer;
