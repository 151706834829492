import axiosHttp from "services/axiosHttp";
import Filters from "views/app-views/employee/level/components/Filters";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchLocationList = createAsyncThunk("fetchLocationList", async ({ filters, page, limit }, { rejectWithValue }) => {
    const token = localStorage.getItem("auth_token");

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
        company: Filters?.company ?? ""
	});

	try {
		const response = await axiosHttp.get(`/location/list?${params}`, config);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const LocationListSlice = createSlice({
	name: "LocationListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchLocationList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchLocationList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});

		builder.addCase(fetchLocationList.rejected, (state, action) => {
			state.isLoading = false;

			if (action.error.message) {
				state.error = action.error.message;
			} else {
				state.error = "Unknown error occurred";
			}
		});
	},
});

export default LocationListSlice.reducer;
