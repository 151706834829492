import axiosHttp from "services/axiosHttp";
import { MEAL_BASE_URL } from "configs/AppConfig";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchScheduleDetail = createAsyncThunk("fetchScheduleDetail", async ({date, mealTypeId}) => {
	const response = await axiosHttp.get(`${MEAL_BASE_URL}mealSchedule?date=${date}&meal_type_id=${mealTypeId}`);
	return response.data.data;
});

const ScheduleDetailSlice = createSlice({
	name: "ScheduleDetailSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	reducers: {
		resetMealScheduleDetailState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchScheduleDetail.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchScheduleDetail.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchScheduleDetail.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export const { resetMealScheduleDetailState } = ScheduleDetailSlice.actions;
export default ScheduleDetailSlice.reducer;
