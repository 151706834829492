import { combineReducers } from "redux";

//common slices
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import statusListSlice from "./slices/statusListSlice";
import countsSlice from "./slices/countSlice";
import statusListAllSlice from "./slices/statusListSliceAll";
import commentListSlice from "./slices/commentListSlice";
import commentCreateSlice from "./slices/commentCreateSlice";
import firebaseNotificationSlice from "./slices/firebaseNotificationSlice";
import PermissionSlice from "../store/slices/permissionSlice";
import MyDetailSlice from "../store/slices/userDetailsSlice";
import countStatusSlice from "../store/slices/countStatusSlice";

//module slices
import * as authSlices from "../views/auth-views/config/reducer";
import * as leaderboardSlices from "../views/analytics-views/dashboards/sub-dashboards/internal/leaderboard/config/reducer";
import * as orgChartSlices from "../views/app-views/org-chart/config/reducer";
import * as newsSlices from "../views/app-views/news/config/reducer";
import * as settingsSlices from "../views/app-views/settings/config/reducer";
import * as organizationSlices from "../views/app-views/organization/config/reducer";
import * as employeeSlices from "../views/app-views/employee/config/reducer";
import * as burningIssuesSlices from "../views/app-views/burning-issues/config/reducer";
import * as wfourSlices from "../views/app-views/w-four/config/reducer";
import * as complainSlices from "../views/app-views/complains/config/reducer";
import * as warrantySlices from "../views/app-views/warranty/config/reducer";
import * as dashboardSlices from "../views/app-views/dashboards/config/reducer";
import * as goalBIPSlices from "../views/app-views/goals/config/reducer";
import * as meetingSlices from "../views/app-views/meetings/config/reducer";
import * as stockTakeSlices from "../views/app-views/stock-take/config/reducer";
import * as sopSlices from "../views/app-views/sop/config/reducer";
import * as gisSetupSlices from "../views/app-views/gis/gis-settings/config/reducer";
import * as gisAnalyticsSlices from "../views/analytics-views/dashboards/sub-dashboards/internal/gis-map/config/reducer";
import * as b2cSlices from "../views/app-views/b2c/config/reducer";
import * as dealerSlices from "../views/app-views/dealer/config/reducer";
import * as productSlices from "../views/app-views/product/config/reducer";
import * as dashboardListSlice from "../views/analytics-views/dashboards/config/reducer";
import * as dashboardMGMTSlices from "../views/analytics-views/dashboards-mgmt/config/reducer";
import * as ticketIssueTypesSlices from "../views/app-views/tickets/config/reducer";
import * as permSlices from "../views/admin-views/permission/config/reducer";
import * as mealSlices from "../views/app-views/meal/config/reducer";

import * as rewardSlices from "../views/reward-views/config/reducer";

const rootReducer = (asyncReducers) => (state, action) => {
	const combinedReducer = combineReducers({
		theme,
		auth,
		MyDetailSlice,
		PermissionSlice,
		statusListSlice,
		countsSlice,
		statusListAllSlice,
		commentListSlice,
		commentCreateSlice,
		firebaseNotificationSlice,
		countStatusSlice,
		...asyncReducers,
		...authSlices,
		...leaderboardSlices,
		...orgChartSlices,
		...newsSlices,
		...settingsSlices,
		...organizationSlices,
		...employeeSlices,
		...burningIssuesSlices,
		...wfourSlices,
		...complainSlices,
		...warrantySlices,
		...dashboardSlices,
		...goalBIPSlices,
		...meetingSlices,
		...stockTakeSlices,
		...sopSlices,
		...gisSetupSlices,
		...gisAnalyticsSlices,
		...b2cSlices,
		...dealerSlices,
		...productSlices,
		...dashboardListSlice,
		...dashboardMGMTSlices,
		...permSlices,
		...ticketIssueTypesSlices,
		...rewardSlices,
		...mealSlices,
	});
	return combinedReducer(state, action);
};

export default rootReducer;
