import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// Action to fetch dealer counts
export const fetchDealerCounts = createAsyncThunk("fetchDealerCounts", async () => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`/dealer/counts`, config);
	
	// Process data to group by user and dealer type
	const counts = response.data.data;
	const groupedData = {};
	
	// Process and group the data by user and dealer type
	counts.forEach(item => {
		const user = item.createdByDetails?.fname && item.createdByDetails?.lname 
			? `${item.createdByDetails.fname} ${item.createdByDetails.lname}`
			: "Unknown User";
		
		const dealerType = item.typeDetails?.category_name || "Unknown Type";
		const count = item._count?._all || 0;
		
		if (!groupedData[user]) {
			groupedData[user] = {};
		}
		
		groupedData[user][dealerType] = (groupedData[user][dealerType] || 0) + count;
		
		// Add a total count for each user
		groupedData[user]["Total"] = (groupedData[user]["Total"] || 0) + count;
	});
	
	return {'grouped':groupedData};
});

const dealerCountsSlice = createSlice({
	name: "dealerCountsSlice",
	initialState: {
		isLoading: false,
		data: null,
		groupedData: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchDealerCounts.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchDealerCounts.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload.raw;
			state.groupedData = action.payload.grouped;
		});
		
		builder.addCase(fetchDealerCounts.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default dealerCountsSlice.reducer; 