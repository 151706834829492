import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosHttp from "services/axiosHttp";

//create action
export const createOutletBPR = createAsyncThunk("createOutletBPR", async ({ data }) => {
	const response = await axiosHttp.post(`dealer/module-metadata`, data);
	return response.data;
});

const DealerOutLetsBPRCreate = createSlice({
	name: "DealerOutLetsBPRCreate",
	initialState: {
		isLoading: false,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(createOutletBPR.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(createOutletBPR.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});

		builder.addCase(createOutletBPR.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default DealerOutLetsBPRCreate.reducer;
