import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchMealEmployeeList = createAsyncThunk("fetchMealEmployeeList", async ({ filters, page, limit }, { rejectWithValue }) => {

	const params = new URLSearchParams({
		page: page,
		limit: limit,
		benefits: filters?.benefits ?? "",
	});

	try {
		const response = await axiosHttp.get(`employee/list?${params}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const MealEmployeeListSlice = createSlice({
	name: "MealEmployeeListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchMealEmployeeList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchMealEmployeeList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});

		builder.addCase(fetchMealEmployeeList.rejected, (state, action) => {
			state.isLoading = false;

			if (action.error.message) {
				state.error = action.error.message;
			} else {
				state.error = "Unknown error occurred";
			}
		});
	},
});

export default MealEmployeeListSlice.reducer;
