import axiosHttp from "services/axiosHttp";
import { MEAL_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchMealRequestList = createAsyncThunk("fetchMealRequestList", async ({ filters, page, limit }, { rejectWithValue }) => {
	const formatDate = (date) => (date && date.isValid() ? date.format("YYYY-MM-DD") : "");
	console.log(filters.range);

	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		scheduleId: filters?.scheduleId ?? "",
		locationId: filters?.locationId ?? "",
		mealCategoryId: filters?.mealCategoryId ?? "",
		startDate: formatDate(filters?.range[0]),
		endDate: formatDate(filters?.range[1]),
		is_consumed: filters?.is_consumed ?? "",
	});

	try {
		const response = await axiosHttp.get(`${MEAL_BASE_URL}meal-request/admin?${params}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const MealRequestListSlice = createSlice({
	name: "MealRequestListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchMealRequestList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchMealRequestList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});

		builder.addCase(fetchMealRequestList.rejected, (state, action) => {
			state.isLoading = false;

			if (action.error.message) {
				state.error = action.error.message;
			} else {
				state.error = "Unknown error occurred";
			}
		});
	},
});

export default MealRequestListSlice.reducer;
