import axiosHttp from "services/axiosHttp";
import { MEAL_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// action
export const fetchMealExcelExport = createAsyncThunk("fetchMealExcelExport", async ({ filters }, { rejectWithValue }) => {

	const params = new URLSearchParams({
		keyword: filters?.keyword ?? '',
        supplier_id: filters?.supplier_id ?? '',
        meal_type_id: filters?.meal_type_id ?? '',
        department_id: filters?.department_id ?? '',
        startDate: filters?.startDate ?? '',
        endDate: filters?.endDate ?? '',
	});

	try {
		const response = await axiosHttp.get(`${MEAL_BASE_URL}/report/generate?${params}`, {
			responseType: "blob",
		});

		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "meal_export.xlsx");
		document.body.appendChild(link);
		link.click();

		link.parentNode.removeChild(link);
		window.URL.revokeObjectURL(url);

		return { success: true };
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const MealExcelExportSlice = createSlice({
	name: "MealExcelExportSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchMealExcelExport.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchMealExcelExport.fulfilled, (state) => {
			state.isLoading = false;
		});

		builder.addCase(fetchMealExcelExport.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload || "Unknown error occurred";
		});
	},
});

export default MealExcelExportSlice.reducer;
