import axiosHttp from "services/axiosHttp";
import { message } from "antd";
import { MEAL_BASE_URL } from "configs/AppConfig";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");


//action
export const mealScheduleCreate = createAsyncThunk("mealScheduleCreate", async ( data,{rejectWithValue}) => {
	try {
		const response = await axiosHttp.post(`${MEAL_BASE_URL}mealSchedule`, data);
		message.success("Schedule has been created successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const ScheduleCreateSlice = createSlice({
	name: "ScheduleCreateSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	
	reducers: {
		resetMealScheduleCreateState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},
	
	extraReducers: (builder) => {
		builder.addCase(mealScheduleCreate.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(mealScheduleCreate.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(mealScheduleCreate.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export const { resetMealScheduleCreateState } = ScheduleCreateSlice.actions;
export default ScheduleCreateSlice.reducer;
