import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosHttp from "services/axiosHttp";
import { message } from "antd";
import { MEAL_BASE_URL } from "configs/AppConfig";

//CREATE ACTION
export const updateSupplier = createAsyncThunk("updateSupplier", async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.patch(`${MEAL_BASE_URL}supplier/${id}`, data);
        message.success("Supplier has been updated successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const SupplierUpdateSlice = createSlice({
	name: "SupplierUpdateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

    reducers: {
		resetUpdateState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(updateSupplier.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(updateSupplier.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(updateSupplier.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetUpdateState } = SupplierUpdateSlice.actions;
export default SupplierUpdateSlice.reducer;
