import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchDealerOutletsBPRList = createAsyncThunk("fetchDealerOutletsBPRList", async ({ page, limit, filters }) => {
	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		type: filters?.type ?? "",
	});

	const response = await axiosHttp.get(`dealer/module-metadata?${params}`);

	return response.data.data;
});

const DealerOutletsBPRListSlice = createSlice({
	name: "DealerOutletsBPRListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchDealerOutletsBPRList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchDealerOutletsBPRList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = String(action.payload.parsedPage);
			state.total = action.payload.total;
		});

		builder.addCase(fetchDealerOutletsBPRList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default DealerOutletsBPRListSlice.reducer;
