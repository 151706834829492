import axiosHttp from "services/axiosHttp";
import { message } from "antd";
import { MEAL_BASE_URL } from "configs/AppConfig";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");


//action
export const creatMealType = createAsyncThunk("creatMealType", async ( data,{rejectWithValue}) => {
	try {
		const response = await axiosHttp.post(`${MEAL_BASE_URL}meal-type`, data);
		message.success("Meal type has been created successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const MealTypeCreateSlice = createSlice({
	name: "MealTypeCreateSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	
	reducers: {
		resetCreateState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},
	
	extraReducers: (builder) => {
		builder.addCase(creatMealType.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(creatMealType.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(creatMealType.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export const { resetCreateState } = MealTypeCreateSlice.actions;
export default MealTypeCreateSlice.reducer;
