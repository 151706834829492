import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosHttp from "services/axiosHttp";
import { message } from "antd";
import { MEAL_BASE_URL } from "configs/AppConfig";

//CREATE ACTION
export const mealScheduleUpdate = createAsyncThunk("mealScheduleUpdate", async ({ scheduleId, data }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.patch(`${MEAL_BASE_URL}mealSchedule/${scheduleId}`, data);
        message.success("Schedule has been updated successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const ScheduleUpdateSlice = createSlice({
	name: "ScheduleUpdateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

    reducers: {
		resetMealScheduleUpdateState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(mealScheduleUpdate.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(mealScheduleUpdate.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(mealScheduleUpdate.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetMealScheduleUpdateState } = ScheduleUpdateSlice.actions;
export default ScheduleUpdateSlice.reducer;
