import axiosHttp from "services/axiosHttp";
import { MEAL_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchMealSchedule = createAsyncThunk("fetchMealSchedule", async ({ date }, { rejectWithValue }) => {

	try {
		const response = await axiosHttp.get(`${MEAL_BASE_URL}/mealSchedule?date=${date}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const MealScheduleSlice = createSlice({
	name: "MealScheduleSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchMealSchedule.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchMealSchedule.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});

		builder.addCase(fetchMealSchedule.rejected, (state, action) => {
			state.isLoading = false;

			if (action.error.message) {
				state.error = action.error.message;
			} else {
				state.error = "Unknown error occurred";
			}
		});
	},
});

export default MealScheduleSlice.reducer;
