import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosHttp from "services/axiosHttp";
import { message } from "antd";
import { MEAL_BASE_URL } from "configs/AppConfig";

//CREATE ACTION
export const updateMeal = createAsyncThunk("updateMeal", async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.patch(`${MEAL_BASE_URL}menu-item/${id}`, data);
        message.success("Meal item has been updated successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const MealUpdateSlice = createSlice({
	name: "MealUpdateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

    reducers: {
		resetMealUpdateState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(updateMeal.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(updateMeal.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(updateMeal.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetMealUpdateState } = MealUpdateSlice.actions;
export default MealUpdateSlice.reducer;
