import axiosHttp from "services/axiosHttp";
import { MEAL_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchSupplierSchedule = createAsyncThunk("fetchSupplierSchedule", async ({filters},{ rejectWithValue }) => {
	try {

		const params = new URLSearchParams({
			month: filters?.month ?? "",
			supplier_id: filters?.supplier_id ?? "",
			meal_type_id: filters?.meal_type_id ?? "",
		});

		const response = await axiosHttp.get(`${MEAL_BASE_URL}mealSchedules?${params}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const SupplierScheduleListSlice = createSlice({
	name: "SupplierScheduleListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchSupplierSchedule.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchSupplierSchedule.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});

		builder.addCase(fetchSupplierSchedule.rejected, (state, action) => {
			state.isLoading = false;

			if (action.error.message) {
				state.error = action.error.message;
			} else {
				state.error = "Unknown error occurred";
			}
		});
	},
});

export default SupplierScheduleListSlice.reducer;
