import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const mealRoutes = [
	{
		key: "supplier-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/meal/supplier/list`,
		component: React.lazy(() => import("views/app-views/meal/pages/SupplierList")),
	},
	{
		key: "supplier-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/meal/supplier/create`,
		component: React.lazy(() => import("views/app-views/meal/pages/SupplierCreate")),
	},
	{
		key: "supplier-schedule",
		exact: true,
		path: `${APP_PREFIX_PATH}/meal/supplier/schedule`,
		component: React.lazy(() => import("views/app-views/meal/pages/SupplierSchedule")),
	},
	{
		key: "schedule-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/meal/schedule/create/:id`,
		component: React.lazy(() => import("views/app-views/meal/pages/ScheduleCreate")),
	},
	{
		key: "meal-configure",
		exact: true,
		path: `${APP_PREFIX_PATH}/meal/configure`,
		component: React.lazy(() => import("views/app-views/meal/pages/MealConfiguration")),
	},
	{
		key: "supplier-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/meal/supplier/edit/:id`,
		component: React.lazy(() => import("views/app-views/meal/pages/SupplierEdit")),
	},
	{
		key: "meal-report",
		exact: true,
		path: `${APP_PREFIX_PATH}/meal/report`,
		component: React.lazy(() => import("views/app-views/meal/pages/MealReport")),
	},
	{
		key: "meal-admin-request",
		exact: true,
		path: `${APP_PREFIX_PATH}/meal/admin/request`,
		component: React.lazy(() => import("views/app-views/meal/pages/MealRequest")),
	},
	{
		key: "meal-admin-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/meal/admin/list`,
		component: React.lazy(() => import("views/app-views/meal/pages/MealRequestList")),
	},
];
