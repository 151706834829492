import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosHttp from "services/axiosHttp";
import { message } from "antd";
import { MEAL_BASE_URL } from "configs/AppConfig";

//CREATE ACTION
export const updateSupplierStatus = createAsyncThunk("updateSupplierStatus", async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.patch(`${MEAL_BASE_URL}supplier/status/${id}`, data);
        message.success("Supplier has been updated successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const SupplierStatusSlice = createSlice({
	name: "SupplierStatusSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	extraReducers: (builder) => {
		builder.addCase(updateSupplierStatus.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(updateSupplierStatus.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(updateSupplierStatus.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default SupplierStatusSlice.reducer;
