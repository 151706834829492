import { Button, Col, Divider, Dropdown, Form, Input, Row, Select, Space } from "antd";
import React from "react";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import "./styles.css";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchLevelList } from "../redux/levelListSlice";
import { useEffect } from "react";
import { fetchCompanyList } from "views/app-views/organization/company/redux/companyListSlice";
import { fetchStratumList } from "../../stratum/redux/stratumListSlice";
import PermissionManager from "utils/class/PermissionManager";
import { USER } from "constants/AuthConstant";

const Filters = ({ callbackFunction, resetFunction }) => {
	const [filterForm] = Form.useForm();
	const [resetButtonDisabled, setResetButtonDisabled] = React.useState(true);

	const dispatch = useDispatch();

	const resetFilterFields = () => {
		if (myCompany !== undefined) {
			filterForm.resetFields(["companyName"]);
			filterForm.resetFields(["stratum"]);
		} else {
			filterForm.resetFields();
		}
		setResetButtonDisabled(true);
		resetFunction(null);
		callbackFunction("", filterForm.getFieldValue("company"), filterForm.getFieldValue("stratum"));
	};

	const companyChangeHandler = (e) => {
		if (e.target.value.length !== 0) {
			setResetButtonDisabled(false);
			callbackFunction(e.target.value, filterForm.getFieldValue("company"));
		} else {
			if (filterForm.getFieldValue("company") === undefined) {
				setResetButtonDisabled(true);
			} else {
				setResetButtonDisabled(false);
			}
			callbackFunction("", filterForm.getFieldValue("company"), filterForm.getFieldValue("stratum"));
		}
	};
	const companyList = useSelector((state) => state?.companyListSlice?.data?.docs);
	const stratumList = useSelector((state) => state?.stratumListSlice?.data?.docs);
	const myCompany = PermissionManager.getItem(USER)?.user?.employee?.companyId;

	const companySelectHandler = (value) => {
		filterForm.resetFields(["stratum"]);
		dispatch(
			fetchStratumList({
				page: 1,
				limit: 100,
				filters: { company: filterForm.getFieldValue("company") },
			})
		);
		filterForm.validateFields().then((values) => {
			handleDispatch(values.companyName, value, values.stratum);
		});
	};

	const stratumSelectHandler = (value) => {
		filterForm.validateFields().then((values) => {
			handleDispatch(values.companyName, values.company, values.stratum);
		});
	};

	const handleDispatch = (keyword, company, stratum) => {
		setResetButtonDisabled(false);
		stratum = stratum === 0 ? null : stratum;
		callbackFunction(keyword, company, stratum);
	};

	useEffect(() => {
		dispatch(fetchCompanyList({ page: 1, limit: 100 }));
		dispatch(fetchStratumList({ page: 1, limit: 100 }));
		filterForm.setFieldsValue({ company: myCompany });
		if (myCompany !== undefined) {
			handleDispatch("", myCompany);
			setResetButtonDisabled(true);
		} else {
			callbackFunction("", filterForm.getFieldValue("company"), filterForm.getFieldValue("stratum"));
		}
	}, []);

	return (
		<Row gutter={[16, 16]} className="mt-4">
			<Col xs={24} sm={24} md={6} lg={6}>
				<Form form={filterForm} layout="vertical">
					<Form.Item name="companyName">
						<Input prefix={<SearchOutlined />} className="search-input" placeholder="Search Level & ID" onChange={companyChangeHandler} />
					</Form.Item>
				</Form>
			</Col>
			<Col xs={24} sm={24} md={18} lg={18} className="col-other-filters">
				<Row gutter={8} className="mt-4">
					<Col>
						<Form form={filterForm} layout="vertical">
							<Form.Item name="company">
								<Select
									placeholder="Company"
									style={{ verticalAlign: "middle", width: 210 }}
									onChange={companySelectHandler}
									disabled={myCompany !== undefined}
									showSearch
									optionFilterProp="children"
								>
									{companyList?.map((val) => (
										<Select.Option key={val?.id} value={val?.id}>
											{val?.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Form>
					</Col>

					<Col>
						<Form form={filterForm} layout="vertical">
							<Form.Item name="stratum">
								<Select
									placeholder="Stratum"
									style={{ verticalAlign: "middle", width: 210 }}
									onChange={stratumSelectHandler}
									disabled={myCompany !== undefined}
									showSearch
									optionFilterProp="children"
								>
									{stratumList?.map((val) => (
										<Select.Option key={val?.id} value={val?.id}>
											{val?.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Form>
					</Col>

					<Col>
						<span className="mr-3">
							<Divider type="vertical" />

							<Button type="link" disabled={resetButtonDisabled} onClick={resetFilterFields}>
								Reset
							</Button>
						</span>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default Filters;
