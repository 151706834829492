import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

export const fetchPOList = createAsyncThunk("fetchPOList", async ({channelId,page,limit,filters}, { rejectWithValue }) => {
	try {
		const params = new URLSearchParams({
			page: page,
			limit: limit,
			keyword: filters?.keyword ?? "",
			status: filters?.status ?? "",
			
		});
		const response = await axiosHttp.get(`${REWARD_BASE_URL}channel/${channelId}/po?${params}`);
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

const RewardsPOListSlice = createSlice({
	name: "RewardsPOListSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
		page:1,
		total:0,
	},
	
	extraReducers: (builder) => {
		builder.addCase(fetchPOList.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});
		builder.addCase(fetchPOList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
			state.page = action.payload.data.page;
			state.total = action.payload.data.total;
			console.log("poList", action.payload.data);

		});
		builder.addCase(fetchPOList.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetPOListState } = RewardsPOListSlice.actions;
export default RewardsPOListSlice.reducer;
