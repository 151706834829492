import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchDealerList = createAsyncThunk("fetchDealerList", async ({ page, limit, filters }) => {
	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		type: filters?.type ?? "",
	});

	const response = await axiosHttp.get(`dealer/admin/list?${params}`);
	return response.data.data;
});

const DealerListSlice = createSlice({
	name: "DealerListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchDealerList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchDealerList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchDealerList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default DealerListSlice.reducer;
