import axiosHttp from "services/axiosHttp";
import { MEAL_BASE_URL } from "configs/AppConfig";
import moment from "moment/moment";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchMealReport = createAsyncThunk("fetchMealReport", async ({ page, limit, filters }, { rejectWithValue }) => {
	let start_date = "";
	let end_date = "";

	if (filters?.range?.length === 2) {
		start_date = moment(filters.range[0].$d).format("YYYY-MM-DD");
		end_date = moment(filters.range[1].$d).format("YYYY-MM-DD");
	}

	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		supplier_id: filters?.supplier_id ?? "",
		meal_type_id: filters?.meal_type_id ?? "",
		department_id: filters?.department_id ?? "",
		startDate: start_date ?? "",
		endDate: end_date ?? "",
	});

	try {
		const response = await axiosHttp.get(`${MEAL_BASE_URL}report?${params}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const MealReportSlice = createSlice({
	name: "MealReportSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchMealReport.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchMealReport.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});

		builder.addCase(fetchMealReport.rejected, (state, action) => {
			state.isLoading = false;

			if (action.error.message) {
				state.error = action.error.message;
			} else {
				state.error = "Unknown error occurred";
			}
		});
	},
});

export default MealReportSlice.reducer;
