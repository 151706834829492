import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from "constants/ThemeConstant";
import { getStoredTheme } from "store/slices/themeConfig";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const REWARD_BASE_URL = process.env.REACT_APP_REWARD_BASE_URL;
export const AWS_IMAGE_BUCKET_URL = process.env.REACT_APP_AWS_IMAGE_BUCKET_URL;
export const MEAL_BASE_URL = process.env.REACT_APP_MEAL_BASE_URL;

export const APP_NAME = "iOrel";
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const UNAUTHENTICATED_ENTRY = "/login";
export const FIRSTTIME_RESET_ENTRY = "/first-reset";
export const IMAGE_UPLOAD_URL = "https://9amzq73qyt.us-east-2.awsapprunner.com/api/v1/iorel/upload/create";

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: "en",
	navType: NAV_TYPE_SIDE,
	mobileNav: false,
	currentTheme: getStoredTheme(),
	direction: DIR_LTR,
	blankLayout: false,
};
