import axiosHttp from "services/axiosHttp";
import { message } from "antd";
import { MEAL_BASE_URL } from "configs/AppConfig";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");


//action
export const mealSupplierCreate = createAsyncThunk("mealSupplierCreate", async ( data,{rejectWithValue}) => {
	try {
		const response = await axiosHttp.post(`${MEAL_BASE_URL}supplier`, data);
		message.success("Supplier has been created successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const MealSupplierCreateSlice = createSlice({
	name: "MealSupplierCreateSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	
	reducers: {
		resetMealSupplierCreateState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},
	
	extraReducers: (builder) => {
		builder.addCase(mealSupplierCreate.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(mealSupplierCreate.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(mealSupplierCreate.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export const { resetMealSupplierCreateState } = MealSupplierCreateSlice.actions;
export default MealSupplierCreateSlice.reducer;
